<template>
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		:x="_x"
		:y="_y"
		width="38.5px"
		height="38.167px"
		viewBox="0 0 38.5 38.167"
		xml:space="preserve"
		class="circle-icon"
	>
		<defs>
			<linearGradient id="account-circle-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
				<stop offset="0%" stop-color="#a5fab3" />
				<stop offset="100%" stop-color="#33dd50" />
			</linearGradient>
		</defs>
		<foreignObject x="446" y="318" width="100" height="100">
			<AccountListPopover
				:target="id"
				title="Add accounts"
				:accounts="accounts"
			/>
		</foreignObject>
		<circle
			fill-rule="evenodd"
			clip-rule="evenodd"
			fill="url(#account-circle-gradient)"
			cx="19.115"
			cy="19.094"
			r="17.26"
		/>
		<g>
			<path fill="#FFFFFF" d="M27.355,25.504c-0.242-0.576-0.628-1.056-1.117-1.455c-0.853-0.696-1.828-1.146-2.868-1.47
				c-1.288-0.401-1.285-0.402-0.704-1.616l1.115-2.413c0-0.002,0-0.002,0-0.004c0.271-0.576,0.335-1.152-0.006-1.728
				c-0.035-0.108-0.125-0.229-0.103-0.32c0.673-2.601-0.929-5.061-3.548-5.439c-1.643-0.49-3.477,0.23-4.749,1.869
				c-0.258,0.329-0.511,0.663-0.531,1.107c-0.225,0.285-0.221,0.613-0.186,0.949c-0.295,0.727,0.164,1.453-0.013,2.196
				c-0.178,0.743,0.019,1.473,0.356,2.149c0.342,0.689,0.701,1.371,1.07,2.047c0.295,0.543,0.299,0.901-0.425,1.072
				c-1.057,0.249-2.057,0.667-2.952,1.298c-0.774,0.549-1.399,1.223-1.653,2.165c-0.297,1.091-0.102,1.345,0.998,1.347h14.188
				C27.524,27.259,27.868,26.736,27.355,25.504z M21.289,23.79c-0.579,0.496-1.256,0.771-2.019,0.733
				c-0.76,0.026-1.417-0.223-1.967-0.702c-0.184-0.157-0.639-0.282-0.316-0.704c0.225-0.293,0.422-0.418,0.842-0.219
				c0.962,0.455,1.986,0.474,2.941-0.032c0.453-0.241,0.618,0.024,0.815,0.285C21.852,23.509,21.46,23.643,21.289,23.79z
				M21.721,20.741c-1.979,2.263-4.223,1.865-5.288-0.951c-0.102-0.27-0.299-0.47-0.401-0.715c-0.282-0.674-0.749-1.31-0.03-2.086
				c0.429-0.462,0.145-1.335,0.706-1.832c0.862-0.63,1.731-0.362,2.603-0.028c0.716,0.301,1.469,0.487,2.237,0.359
				c0.57-0.097,0.787,0.174,0.953,0.615c0.163,0.338-0.076,0.734,0.154,1.063c0.042,0.046,0.095,0.065,0.157,0.056
				c0.177,0.427,0.181,0.854-0.002,1.28l0.008-0.013c-0.002,0.001-0.002,0.005-0.002,0.007c-0.104,0.262-0.134,0.563-0.385,0.749
				C21.902,19.604,22.02,20.271,21.721,20.741z"
			/>
		</g>
		<circle
			:id="id"
			fill-rule="evenodd"
			clip-rule="evenodd"
			fill="transparent"
			cx="19.115"
			cy="19.094"
			r="17.26"
		/>
	</svg>
</template>

<script>
import GraphicComponent from './GraphicComponent.vue';
import AccountListPopover from './AccountListPopover';

export default {
	extends: GraphicComponent,

	components: {
		AccountListPopover
	},

	props: {
		accounts: {
			type: Array,
			required: true
		}
	},

	data() {
		return {
			id: this.getId('account-circle')
		};
	}
};
</script>
