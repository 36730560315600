import { render, staticRenderFns } from "./NamespaceListPopover.vue?vue&type=template&id=3a496fe6&scoped=true&"
import script from "./NamespaceListPopover.vue?vue&type=script&lang=js&"
export * from "./NamespaceListPopover.vue?vue&type=script&lang=js&"
import style0 from "./NamespaceListPopover.vue?vue&type=style&index=0&id=3a496fe6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a496fe6",
  null
  
)

export default component.exports